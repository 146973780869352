import axios from '@/axios.js'

const state = () => ({
    nav_menus: [],
})

const mutations = {

    SET_MENUS (state, payload) {
      state.nav_menus = payload
    },
    ADD_MENU (state, item) {
      state.nav_menus.unshift(item)
    },
    UPDATE_MENU (state, term) {
      const termIndex = state.nav_menus.findIndex((p) => p.id === term.id)
      Object.assign(state.nav_menus[termIndex], term)
    },
    REMOVE_MENU (state, itemId) {
      const ItemIndex = state.nav_menus.findIndex((p) => p.id === itemId)
      state.nav_menus.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchMenu ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/nav_menu`)
        .then((response) => {
          commit('SET_MENUS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addMenu ({ commit }, term ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', term)
        .then((response) => {
          commit('ADD_MENU', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateMenu ({ commit }, term) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${term.id}`, term)
        .then((response) => {
          commit('UPDATE_MENU', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeMenu ({ commit }, term) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${term.id}`)
        .then((response) => {
          commit('REMOVE_MENU', term.id)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}